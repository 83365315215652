import React from "react";

export default function ParagraphSection(props) {
  return (
    <div className="w-full py-6 space-y-3">
      <div className="e-title">{props.title}</div>
      <div className="text-gray-600 text-lg">{props.text}</div>
    </div>
  );
}
