import mechanical from "../resources/products/mechanical.jpg";
import electrical from "../resources/products/electrical.jpg";
import hvac from "../resources/products/hvac.jpg";
import plumbing from "../resources/products/plumbing.jpg";
import safety from "../resources/products/safety.jpg";
import cctv from "../resources/products/cctv.jpg";
import network from "../resources/products/network.jpg";
import gas from "../resources/products/gas.jpg";
import marine from "../resources/products/marine.jpg";
import ic from "../resources/products/ic.jpg";
import cosmetics from "../resources/sector/cosmetics2.jpg";

export const products = [
  {
    title: "Mechanical Products",
    link: "/products",
    image: mechanical,
    sub: [
      {
        title: "Spare Parts",
      },
      {
        title: "Strainers",
      },
      {
        title: "Onsite & Offsite Equipment for Oil Refineries",
      },
      {
        title: "Cormpressors",
      },
      {
        title: "Industrial Vacuum & Cleaning Equip",
      },
      {
        title: "Dosing Skids and Systems",
      },
      {
        title: "Marian Containers",
      },
      {
        title: "Pumps",
      },
      {
        title: "Flanges",
      },
      {
        title: "Valves",
      },
      {
        title: "Bearings",
      },
      {
        title: "Custody Metering System",
      },
      {
        title: "Pipes & Fittings",
      },
      {
        title: "Heat Exchangers",
      },
      {
        title: "Marine Valves",
      },
      {
        title: "Turbines",
      },
      {
        title: "Casting Re-Engineering Products",
      },
      {
        title: "Seals",
      },
    ],
  },
  {
    title: "Electrical Products",
    link: "/products",
    image: electrical,
    sub: [
      {
        title: "Switchgear",
      },
      {
        title: "MV Substations",
      },
      {
        title: "Motors",
      },
      {
        title: "Circuit Breakers",
      },
      {
        title: "Magnetic Starters",
      },
      {
        title: "Transformers",
      },
      {
        title: "Industrial Enclosures",
      },
      {
        title: "Power lnsulators",
      },
      {
        title: "Solar Systems",
      },
      {
        title: "Variable Speed Drives",
      },
      {
        title: "Relays",
      },
      {
        title: "Fuses",
      },
      {
        title: "Controls",
      },
      {
        title: "Motor Control Centers",
      },
      {
        title: "Cables and Wires",
      },
      {
        title: "Battery Charger",
      },
      {
        title: "Load Banks",
      },
      {
        title: "Busway System",
      },
      {
        title: "Cable Splicing & Termination Kit",
      },
      {
        title: "Cable Trays",
      },
      {
        title: "Trucking, Wire Ways",
      },
      {
        title: "Transtormer Alum. Strip",
      },
      {
        title: "Conduit & Fittings",
      },
      {
        title: "Spare Part",
      },
      {
        title: "Lighting Fixtures",
      },
      {
        title: "Electrical Testing Equipment",
      },
    ],
  },
  {
    title: "HVAC Products",
    link: "/products",
    image: hvac,
    sub: [
      {
        title: "Control Dampers",
      },
      {
        title: "Smoke Control Dampers",
      },
      {
        title: "HVAC Piping & Pumps",
      },
      {
        title: "Cooling Towers",
      },
      {
        title: "Valves for HVAC Piping",
      },
      {
        title: "Air Cooled Chillers",
      },
      {
        title: "Water Cooled Chillers",
      },
      {
        title: "Sensors and Transmitters",
      },
      {
        title: "Vibration and Seismic Controls",
      },
      {
        title: "HVAC Fans",
      },
      {
        title: "HVAC Power Ventilators",
      },
      {
        title: "Special Exhaust Systems",
      },
      {
        title: "Diffusers, Registers & Grillers",
      },
      {
        title: "HVAC Variable Frequency Drives",
      },
      {
        title: "Temperature Measurement Control",
      },
      {
        title: "Heaters",
      },
      {
        title: "Heat Exchanger for HVAC",
      },
      {
        title: "HVAC & Duct Insulation",
      },
      {
        title: "Instrumentation & Control Devices for HVAC",
      },
      {
        title: "Central & Packaged HVAC Equipment",
      },
      {
        title: "Variable Air Volume Units",
      },
      {
        title: "Spare Parts",
      },
      {
        title: "Air Duct Accessories",
      },
      {
        title: "Air Terminal Units",
      },
    ],
  },
  {
    title: "Construction Works",
    link: "/products",
    image: plumbing,
    sub: [
      {
        title: "Plumbing",
      },
      {
        title: "All Road Works",
      },
      {
        title: "Safety and Security",
      },
      {
        title: "All Building Works",
      },
      {
        title:
          "Maintenance and Restoration Contracts for All Facilities and Buildings",
      },
      {
        title: "Casting of Concrete",
      },
      {
        title: "Electromechanical Central Air Conditioning",
      },
      {
        title: "Electrical Maintenance",
      },
    ],
  },
  {
    title: "Safety Equipments",
    link: "/products",
    image: safety,
    sub: [
      {
        title: "Ear Protection",
      },
      {
        title: "Hand Protection",
      },
      {
        title: "Oil & Chemical Spill Kits",
      },
      {
        title: "Safety Barriers",
      },
      {
        title: "Foot Protection",
      },
      {
        title: "Eye Protection",
      },
      {
        title: "Head & Face Protection",
      },
      {
        title: "Hygiene & Personal Care",
      },
      {
        title: "Reflective Safety Cones",
      },
      {
        title: "Gas Dedicators",
      },
      {
        title: "Fall Protection",
      },
      {
        title: "Supply of water coolers",
      },
      {
        title: "Safety Vests",
      },
      {
        title: "Safety Uniforms and all Supplies",
      },
      {
        title: "General PPE",
      },
      {
        title: "Lock out & Tag Out",
      },
    ],
  },
  {
    title: "IT Suppliers",
    link: "/products",
    image: cctv,
    sub: [
      {
        title: "CCTV Cameras",
      },
      {
        title: "Laptops & Tablets ",
      },
      {
        title: "Cables ",
      },
      {
        title: "Chargers",
      },
      {
        title: "Earphones",
      },
      {
        title: "Headphones & speakers",
      },
      {
        title: "Fans ",
      },
      {
        title: "Hard Drives",
      },
      {
        title: "USB Flash Drives",
      },
      {
        title: "Batteries & Adaptors",
      },
      {
        title: "Printers & Ink & Toner",
      },
      {
        title: "Desktops Computers",
      },
      {
        title: "Computer Accessories ",
      },
      {
        title: "NETWORK ACCESORIES",
      },
    ],
  },
  {
    title: "Automation",
    link: "/products",
    image: network,
    sub: [
      {
        title: "Embedded GPU Computer",
      },
      {
        title: "Industrial managed Gigabit Ethernet Switch",
      },
      {
        title: "Temperature Controllers",
      },
      {
        title: "Industrial Panel PC & Monitor",
      },
      {
        title: "Rugged Embedded Computer",
      },
      {
        title: "Convertible Display System",
      },
      {
        title: "Motor Controllers",
      },
      {
        title: "HMI Interface",
      },
      {
        title: "Programmable Controllers",
      },
      {
        title: "AC Drives",
      },
      {
        title: "Biometric Switch & Pilot lights",
      },
      {
        title: "lluminated Pushbutton",
      },
      {
        title: "Electronic Timers/Control Relays",
      },
      {
        title: "I/O Extension modules",
      },
      {
        title: "Plug-In Relays",
      },
      {
        title: "Control Stations & Enclosures",
      },
      {
        title: "Photoelectric Sensors",
      },
      {
        title: "Inductive Sensors",
      },
      {
        title: "Pendant Control and Light Duty Joystick",
      },
      {
        title: "Wireless Limit Switch Solution",
      },
    ],
  },
  {
    title: "Instrumentation and Control",
    link: "/products",
    image: gas,
    sub: [
      {
        title: "DCS System",
      },
      {
        title: "PLC Panels",
      },
      {
        title: "CCTV Systems",
      },
      {
        title: "Transmitters",
      },
      {
        title: "Switches",
      },
      {
        title: "RTU",
      },
      {
        title: "Supervisory Control Systems",
      },
      {
        title: "Telecom Systems",
      },
      {
        title: "lnstrument Panels",
      },
      {
        title: "Gas Metering & Reducing Station",
      },
      {
        title: "Custody Metering Station",
      },
      {
        title: "Pressure, Temperature, Level & Flow Instruments",
      },
      {
        title: "Variable Speed Drives",
      },
      {
        title: "Oil &Gas Metering Skid",
      },
      {
        title: "Gas Detector & Monitoring System",
      },
      {
        title: "Transducers",
      },
      {
        title: "Crude Oil Treatment Equipment",
      },
      {
        title: "Instrument Cables",
      },
    ],
  },
  {
    title: "Marine Products",
    link: "/products",
    image: marine,
    sub: [
      {
        title: "Fixed Fenders Solutions",
      },
      {
        title: "Iransponders",
      },
      {
        title: "Marine Hooks",
      },
      {
        title: "Offshore Mooring Anchors",
      },
      {
        title: "Marine Robots",
      },
      {
        title: "Marine Navigator",
      },
      {
        title: "Open Link Chain and Fittings",
      },
      {
        title: "Deck Equipment",
      },
      {
        title: "Quayside Equipment",
      },
      {
        title: "Floating Fenders",
      },
      {
        title: "Marine Chain and Ropes",
      },
      {
        title: "Marine Valves",
      },
      {
        title: "Floating Hose Equipment",
      },
      {
        title: "Marine Anchors",
      },
    ],
  },
  {
    title: "Chip Level",
    link: "/products",
    image: ic,
    sub: [
      {
        title: "Integrated Circuits",
      },
      {
        title: "Resistors",
      },
      {
        title: "Capacitors",
      },
      {
        title: "Switches",
      },
      {
        title: "Connectors, Interconnectors",
      },
      {
        title: "RF/IF and RFID",
      },
      {
        title: "Potentiometer, Variable Resistors",
      },
      {
        title: "Optoelectronics",
      },
      {
        title: "Transformers",
      },
      {
        title: "Relays",
      },
      {
        title: "Discrete Semiconductor Products",
      },
      {
        title: "Crystals, Oscillators, Resonators",
      },
    ],
  },
  {
    title: "Cosmetics",
    link: "/products",
    image: cosmetics,
    sub: [
      {
        title: "Highliter",
      },
      {
        title: "Lipstick",
      },
      {
        title: "Lip Tint",
      },
      {
        title: "Tint",
      },
      {
        title: "Eyebrow Gel",
      },
      {
        title: "EyebrowShaping",
      },
      {
        title: "Mascara",
      },
      {
        title: "Blusher",
      },
      {
        title: "Eyeliner",
      },
      {
        title: "Waterliner",
      },
      {
        title: "Eyelash",
      },
      {
        title: "Lip Plumper",
      },
      {
        title: "Lip Balm",
      },
      {
        title: "Makeup Settler",
      },
      {
        title: "Makeup foundaation",
      },
      {
        title: "Eyebrow Soap",
      },
      {
        title: "Concealer",
      },
      {
        title: "Primer",
      },
      {
        title: "Serumes",
      },
      {
        title: "Essential Oils",
      },
      {
        title: "Low acidity Perfumes",
      },
      {
        title: "Facewashes",
      },
      {
        title: "Face moisturizer",
      },
      {
        title: "Nail base protection",
      },
      {
        title: "Face mask",
      },
      {
        title: "Lip mask",
      },
      {
        title: "Hair mask",
      },
      {
        title: "Nail polishes",
      },
      {
        title: "Various ready nail designs",
      },
    ],
  },
];
