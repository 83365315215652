import power from "../resources/sector/power.jpg";
import hospitals from "../resources/sector/hospitals.jpg";
import construction from "../resources/sector/construction.jpg";
import teli from "../resources/sector/teli.jpg";
import chemical from "../resources/sector/chemical.jpg";
import industries from "../resources/sector/industries.jpg";
import water from "../resources/sector/water.jpg";
import petro from "../resources/sector/petro.jpg";
import oil from "../resources/sector/oil.jpg";
import refining from "../resources/sector/refining.jpg";
import mining from "../resources/sector/mining.jpg";
import marine from "../resources/sector/marine.jpg";
import railway from "../resources/sector/railway.jpg";
import airways from "../resources/sector/airways.jpg";
import cosmetics from "../resources/sector/cosmetics2.jpg";

export const sectors = [
  {
    title: "Cosmetics",
    image: cosmetics,
    // link: "/services/cosmetics",
  },
  {
    title: "Power Generation and Distribution",
    image: power,
  },
  {
    title: "Industrial Construction Projects",
    image: construction,
  },
  {
    title: "Telecommunications",
    image: teli,
  },
  {
    title: "Chemical Processing",
    image: chemical,
  },
  {
    title: "General Industries",
    image: industries,
  },
  {
    title: "Water Resources",
    image: water,
  },
  {
    title: "Petrochemicals",
    image: petro,
  },
  {
    title: "Oil & Gas",
    image: oil,
  },
  {
    title: "Refining",
    image: refining,
  },
  {
    title: "Mining",
    image: mining,
  },
  {
    title: "Marine",
    image: marine,
  },
  {
    title: "Railway",
    image: railway,
  },
  {
    title: "Aviation",
    image: airways,
  },
  {
    title: "Hospitals and pharmacies",
    image: hospitals,
  },
];
