import total from "../resources/clients/total.png";
import alayuni from "../resources/clients/alayuni.png";
import arabiancement from "../resources/clients/arabiancement.jpg";
import dpworld from "../resources/clients/dpworld.png";
import nomac from "../resources/clients/nomac.jpg";
import psi from "../resources/clients/psi.png";
import rcp from "../resources/clients/rcp.png";
import rsgt from "../resources/clients/rsgt.png";
import sans from "../resources/clients/sans.jpg";
import ucic from "../resources/clients/ucic.jpg";
import zahran from "../resources/clients/zahran.jpg";
import bugshan from "../resources/clients/BUGSHAN.png";
import fakiehhos from "../resources/clients/fakiehhos.webp";
import fakieh from "../resources/clients/fakieh.jpg";
import princesa from "../resources/clients/princesa.png";
import roseberry from "../resources/clients/roseberry.jpeg";

export const clients = [
  {
    title: "Total",
    image: total,
  },
  {
    title: "alayuni",
    image: alayuni,
  },
  {
    title: "arabiancement",
    image: arabiancement,
  },
  {
    title: "dpworld",
    image: dpworld,
  },
  {
    title: "nomac",
    image: nomac,
  },
  {
    title: "psi",
    image: psi,
  },
  {
    title: "rcp",
    image: rcp,
  },
  {
    title: "rsgt",
    image: rsgt,
  },
  {
    title: "sans",
    image: sans,
  },
  {
    title: "ucic",
    image: ucic,
  },
  {
    title: "zahran",
    image: zahran,
  },
  {
    title: "bugshan",
    image: bugshan,
  },
  {
    title: "fakiehhos",
    image: fakiehhos,
  },
  {
    title: "fakieh",
    image: fakieh,
  },
  {
    title: "princesa",
    image: princesa,
  },
  {
    title: "roseberry",
    image: roseberry,
  },
];
