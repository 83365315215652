import React from "react";
import Layout from "../Layout";
import { sectors } from "../../data/sectors";
import BannerSmall from "../sections/BannerSmall";

import MediaCard from "../cards/MediaCard";
import bannerImg from "../../resources/sector/construction.jpg";
import ContactSection from "../sections/ContactSection";

export default function Services() {
  return (
    <div>
      <Layout>
        <BannerSmall
          title="Services"
          text="We offer our services in wide variety of sectors"
          bg={bannerImg}
        />
        {/* Sectors */}
        <div className="bg-gray-50">
          <div className="container mx-auto px-10 py-6 space-y-10">
            <div className="e-title">sectors we serve</div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {sectors.map((sector, index) => (
                <MediaCard
                  key={index}
                  title={sector.title}
                  image={sector.image}
                  link={sector.link}
                />
              ))}
            </div>
          </div>
        </div>
        {/* Sectors */}
        <ContactSection />
      </Layout>
    </div>
  );
}
