import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { clients } from "../../data/clients";

export default function ClientSlider() {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="bg-gray-50">
      <div className="container mx-auto p-10 space-y-10">
        <div className="e-title">Our Clients</div>
        <Slider {...settings}>
          {clients.map((client, index) => (
            <div className="p-4">
              <div className=" h-32 p-4 bg-white flex items-center justify-center">
                <img className="h-full  object-fit rounded-t-lg " src={client.image} alt={client.title} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
