import React from "react";
import { Switch, Route } from "react-router-dom";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Home from "./components/pages/Home";
import Products from "./components/pages/products/Products";
import Services from "./components/pages/Services";
import CosmeticsMain from "./components/pages/services/CosmeticsMain";

export default function Routes() {
  return (
    <div>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route exact path="/services/cosmetics">
          <CosmeticsMain />
        </Route>
        <Route exact path="/services">
          <Services />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/products">
          <Products />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  );
}
