import React from "react";
import Layout from "../Layout";
import Banner from "../sections/Banner";
import bannerImg from "../../resources/images/banner1.jpg";
import bannerImg2 from "../../resources/sector/construction.jpg";
import productsImage from "../../resources/products/electrical.jpg";
import ParagraphSection from "../sections/ParagraphSection";

import { sectors } from "../../data/sectors";
import { products } from "../../data/products";
import ClientSlider from "../sections/ClientSlider";
import ContactSection from "../sections/ContactSection";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div>
      <Layout>
        <div className="">
          <Banner
            title="Edraak Group"
            text="We take pride in our integrity, honesty, quality, and our drive for customer satisfaction, developing our internal capabilities and fulfilling our commitments."
            bg={bannerImg}
          />
        </div>
        <div className="bg-white">
          <div className="container mx-auto p-10">
            <ParagraphSection
              title="about"
              text="Edraak is a Saudi company since 1997 specialized in commercial and industrial supplies such as tools, machines and spare parts for 
general contracting projects, technology, trade, distribution of 
consumer goods & products and logistics services. Our services 
meet the growing needs of consumers and help government 
sectors and leading companies in the Kingdom to achieve their 
goals and meet their needs and aspirations with the best 
efficiency and quality."
            />
          </div>
        </div>

        {/* Sectors */}

        <div className="bg-gray-50">
          <div className="container mx-auto p-10 ">
            <div className="grid lg:grid-cols-2 gap-10">
              <div className="w-full h-full">
                <img className="w-full h-full object-cover rounded-lg" src={bannerImg2} />
              </div>
              <div className="">
                <div className="e-title">sectors we serve</div>
                <div className="text-lg py-4 hidden">
                  It is a long established fact that a reader will be distracted by the readable content of a page when
                  looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                  distribution of letters, as opposed to using 'Content here, content here', making it look like
                  readable English.
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {sectors.slice(0, 8).map((sector, index) => (
                    <div className="e-pill-text" key={index}>
                      {sector.title}
                    </div>
                  ))}
                </div>
                <div className="py-4">
                  <Link to="/services" className="inline-block px-3 py-1 rounded-lg bg-primary-500 text-white">
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sectors */}

        {/* products */}

        <div className="bg-white">
          <div className="container mx-auto p-10 ">
            <div className="grid lg:grid-cols-2 gap-10">
              <div className="">
                <div className="e-title">Product</div>
                <div className="text-lg py-4 hidden">
                  It is a long established fact that a reader will be distracted by the readable content of a page when
                  looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                  distribution of letters, as opposed to using 'Content here, content here', making it look like
                  readable English.
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {products.slice(0, 8).map((product, index) => (
                    <div className="e-pill-text" key={index}>
                      {product.title}
                    </div>
                  ))}
                </div>
                <div className="py-4">
                  <Link to="/products" className="inline-block px-3 py-1 rounded-lg bg-primary-500 text-white">
                    Know More
                  </Link>
                </div>
              </div>

              <div className="w-full h-full">
                <img className="w-full h-full object-cover rounded-lg" src={productsImage} />
              </div>
            </div>
          </div>
        </div>
        {/* products */}

        {/* Clients */}
        <ClientSlider />
        {/* Clients */}

        <ContactSection />
      </Layout>
    </div>
  );
}
