import React from "react";
import { useHistory } from "react-router";

export default function MediaCard(props) {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        props?.link ? history.push(props.link) : console.log(props.title);
      }}
      className={`bg-white rounded-lg shadow ${
        props.link ? " cursor-pointer" : ""
      }`}
    >
      <div className="h-32 w-full">
        <img
          className="w-full h-full object-cover rounded-t-lg"
          src={props.image}
          alt={props.title}
        />
      </div>
      <div className="p-4 ">
        <div className="text-lg uppercase font-medium text-gray-600">
          {props.title}
        </div>
      </div>
    </div>
  );
}
