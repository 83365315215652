import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../resources/logo.png";
import { useWindowScroll } from "react-use";
import { AiOutlineCloseCircle, AiOutlineMenu } from "react-icons/ai";

export default function Header() {
  const { y } = useWindowScroll();

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    if (y > 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [y]);
  const navigation = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "About",
      link: "/about",
    },
    {
      title: "Services",
      link: "/services",
    },
    {
      title: "Products",
      link: "/products",
    },
    {
      title: "Contact",
      link: "/contact",
    },
  ];

  const [menu, setMenu] = useState(false);

  return (
    <>
      <div className={`fixed top-0 left-0 w-full ${scroll ? "bg-white shadow" : ""}`} style={{ zIndex: "1000" }}>
        <div className="container mx-auto px-10 py-4 flex items-center relative">
          <div className="inline-block">
            <img src={logo} className="w-16" alt="logo" />
          </div>
          <div className="inline-block uppercase text-primary-500 text-xl font-medium px-3">Edraak Group</div>
          <div className="inline-block ml-auto">
            <div className="hidden md:inline-flex space-x-2 text-gray-600 uppercase">
              {navigation.map((item, index) => (
                <NavLink
                  key={index}
                  exact
                  className="p-1 px-3 rpunded-lg"
                  activeClassName="bg-primary-50 text-primary-500 p-1 px-3 rounded-lg"
                  to={item.link}
                >
                  {item.title}
                </NavLink>
              ))}
            </div>

            <div className="inline-block md:hidden">
              <AiOutlineMenu onClick={() => setMenu(true)} size="30" />
            </div>
          </div>
          {menu && (
            <div className="fixed top-0 left-0 w-full h-screen bg-white p-6">
              <div className="flex items-center py-4 justify-end">
                <AiOutlineCloseCircle onClick={() => setMenu(false)} size="30" className="text-red-500" />
              </div>
              <div className="flex flex-col space-y-3">
                {navigation.map((item, index) => (
                  <NavLink
                    key={index}
                    exact
                    className="p-1 px-3 rpunded-lg"
                    activeClassName="bg-primary-50 text-primary-500 p-1 px-3 rounded-lg"
                    to={item.link}
                  >
                    <div onClick={() => setMenu(false)}>{item.title}</div>
                  </NavLink>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
