import React from "react";
import Layout from "../../Layout";
import BannerSmall from "../../sections/BannerSmall";
import mechanical from "../../../resources/products/mechanical.jpg";
import { products } from "../../../data/products";

export default function Products() {
  return (
    <div>
      <Layout>
        <BannerSmall title="Products" text="" bg={mechanical} />
        {products.map((product, index) => (
          <div
            id={product.title}
            key={index}
            className={(index + 1) % 2 == 0 ? "bg-gray-50" : "bg-white"}
          >
            <div className="container mx-auto p-10">
              <div className="grid lg:grid-cols-2 gap-10">
                {(index + 1) % 2 != 0 && (
                  <div className="w-full h-full">
                    <img
                      className="w-full h-full object-cover rounded-lg"
                      src={product.image}
                    />
                  </div>
                )}
                <div className="">
                  <div className="e-title">{product.title}</div>
                  <div className="text-lg py-4 hidden">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English.
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    {product.sub &&
                      product.sub.map((item, index) => (
                        <div className="e-pill-text" key={index}>
                          {item.title}
                        </div>
                      ))}
                  </div>
                </div>
                {(index + 1) % 2 == 0 && (
                  <div className="w-full h-full">
                    <img
                      className="w-full h-full object-cover rounded-lg"
                      src={product.image}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </Layout>
    </div>
  );
}
