import React from "react";

export default function BannerSmall(props) {
  return (
    <div
      style={{
        backgroundImage: `url("${props.bg}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "60vh",
      }}
    >
      <div className="w-full h-full text-bg-slide">
        <div className="container h-full w-full mx-auto p-10">
          <div className="lg:w-1/2 w-full flex flex-col justify-center h-full">
            <div className="text-3xl font-bold uppercase text-primary-500">{props.title}</div>
            <div className="pt-4 text-lg text-gray-600">{props.text}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
