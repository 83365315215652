import React from "react";
import { Link } from "react-router-dom";

export default function ContactSection() {
  return (
    <div className="bg-primary-500">
      <div className="container mx-auto p-10 flex md:flex-row flex-col items-center justify-between">
        <div className="font-light text-4xl text-white">Need to know more about us</div>
        <Link to="/contact">
          <div className="inline-block px-3 py-2 rounded-lg bg-primary-50 hover:bg-white text-primary-500 cursor-pointer text-lg">
            Contact Us
          </div>
        </Link>
      </div>
    </div>
  );
}
