import React from "react";
import Layout from "../../Layout";
import cosmetics from "../../../resources/sector/cosmeticsbg.jpg";
import { products } from "../../../data/products";
import BannerSmall from "../../sections/BannerSmall";
export default function CosmeticsMain() {
  const product = products.find((i) => i.title === "Cosmetics");
  return (
    <div>
      <Layout>
        <BannerSmall title="Cosmetics" text="" bg={cosmetics} />
        <div id={product.title} className={"bg-white"}>
          <div className="container mx-auto p-10">
            <div className="grid lg:grid-cols-2 gap-10">
              <div className="w-full h-full">
                <img
                  className="w-full h-full object-cover rounded-lg"
                  src={product.image}
                />
              </div>

              <div className="">
                <div className="e-title">{product.title}</div>
                <div className="text-lg py-4">
                  {/* We manage and sell a variety of cosmetics products to local
                  dealers and distribution networks & operations in the Kingdom
                  of Saudi Arabia. For a full list of our products visit the
                  following website:{" "}
                  <a href="https://norita21.com/" target="_blank">
                    https://norita21.com/
                  </a> */}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {product.sub &&
                    product.sub.map((item, index) => (
                      <div className="e-pill-text" key={index}>
                        {item.title}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
