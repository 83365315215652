import React from "react";
import Layout from "../Layout";
import BannerSmall from "../sections/BannerSmall";
import bannerImg from "../../resources/images/contact.jpg";
import { BiMailSend, BiPhoneCall } from "react-icons/bi";
import { BsPhone } from "react-icons/bs";

export default function Contact() {
  return (
    <div>
      <Layout>
        <BannerSmall title="Contact us" text="" bg={bannerImg} />
        <div className="w-full bg-primary-500">
          <div className="container mx-auto p-10 text-white">
            <div className="text-3xl  font-bold text-center">Edraak International Trading Co. Limited Liability</div>
            <div className="text-lg text-center">
              7953 - Khalid Bin Al-walid - Business Advance Center - 5th Floor Jeddah 4959 - 22234 - Kingdom of Saudi
              Arabia
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="container mx-auto p-10 text-white">
            <div className="grid md:grid-cols-2 gap-6">
              <div className="e-transition bg-gray-100 text-gray-600 hover:text-white hover:bg-primary-500 p-4 space-y-4 rounded-lg">
                <div className="text-2xl font-bold text-center ">Head Office</div>
                <div className="grid grid-cols-3 gap-6">
                  <div className="flex flex-col items-center text-lg space-y-4">
                    <BiMailSend size="50" />
                    <div>info@edraak.net</div>
                  </div>
                  <div className="flex flex-col items-center text-lg space-y-4">
                    <BiPhoneCall size="50" /> <div>00966126524127</div>
                  </div>
                  <div className="flex flex-col items-center text-lg space-y-4">
                    <BsPhone size="50" />
                    <div>00966559950291</div>
                  </div>
                </div>
              </div>
              <div className="e-transition bg-gray-100 text-gray-600 hover:text-white hover:bg-primary-500 p-4 space-y-4 rounded-lg">
                <div className="text-2xl font-bold text-center ">Jeddah - Branch</div>
                <div className="grid grid-cols-3 gap-6">
                  <div className="flex flex-col items-center text-lg space-y-4">
                    <BiMailSend size="50" />
                    <div>supply@edraak.net</div>
                  </div>
                  <div className="flex flex-col items-center text-lg space-y-4">
                    <BiPhoneCall size="50" /> <div>00966126044165</div>
                  </div>
                  <div className="flex flex-col items-center text-lg space-y-4">
                    <BsPhone size="50" />
                    <div>00966537207412</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
