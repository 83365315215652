import React from "react";
import { Link } from "react-router-dom";
import vision from "../../resources/vision.png";
import pattern from "../../resources/pattern.png";
import { BiMailSend, BiPhoneCall } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";

export default function Footer() {
  const footer = [
    {
      title: "Products",
      link: "/products",
      submenu: [
        {
          title: "Mecanical Products",
          link: "/products",
        },
        {
          title: "Electrical Products",
          link: "/products",
        },
        {
          title: "HVAC Products",
          link: "/products",
        },
        {
          title: "Construction Works",
          link: "/products",
        },
        {
          title: "Safety Equipments",
          link: "/products",
        },
        {
          title: "Steel Works and Tank Fabrications",
          link: "/products",
        },
        {
          title: "Workshop and Lubrications",
          link: "/products",
        },
        {
          title: "Automation",
          link: "/products",
        },
        {
          title: "Instrumentation and Control",
          link: "/products",
        },
        {
          title: "Marine Products",
          link: "/products",
        },
      ],
    },
    {
      title: "Company",
      link: "",
      submenu: [
        {
          title: "About",
          link: "/about",
        },
        {
          title: "Services",
          link: "/services",
        },
        {
          title: "Clients",
          link: "/about",
        },
        {
          title: "Suppliers",
          link: "/about",
        },
      ],
    },
  ];
  return (
    <div
      className="bg-white border-t-4 border-primary-500"
      style={{
        backgroundImage: `url("${pattern}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div className="container mx-auto p-10">
        <div className="flex justify-between flex-wrap">
          {footer.map((menu, index) => (
            <div key={index} className="lg:w-1/4 w-1/2 space-y-2">
              <div className="uppercase font-bold text-primary-500">{menu.title}</div>
              {menu.submenu.map((item, i) => (
                <div key={i} className="text-sm text-gray-600 hover:text-primary-500">
                  <Link to={item.link}>{item.title}</Link>
                </div>
              ))}
            </div>
          ))}

          <div className="lg:w-1/4 w-1/2 space-y-2">
            <div className="uppercase font-bold text-primary-500">Contact</div>
            <div className="text-sm text-gray-600 hover:text-primary-500">
              7953 - Khalid Bin Al-walid - Business Advance Center - 5th Floor Jeddah 4959 - 22234 - Kingdom of Saudi
              Arabia
            </div>
            <div className="text-sm text-gray-600 hover:text-primary-500">
              <a className="inline-flex space-x-2 items-center" href="tel:00966559950291">
                <BiPhoneCall /> <div>00966559950291</div>
              </a>
            </div>
            <div className="text-sm text-gray-600 hover:text-primary-500">
              <a className="inline-flex space-x-2 items-center" href="tel:+966507130688">
                <FaWhatsapp /> <div>+966507130688</div>
              </a>
            </div>
            <div className="text-sm text-gray-600 hover:text-primary-500">
              <a className="inline-flex space-x-2 items-center" href="mailto:supply@edraak.net">
                <BiMailSend />
                <div>supply@edraak.net</div>
              </a>
            </div>
          </div>
          <div className="lg:w-1/4 w-full px-4">
            <img src={vision} className="w-full" alt="vision2030" />
          </div>
        </div>
      </div>
    </div>
  );
}
