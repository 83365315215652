import React from "react";
import Layout from "../Layout";
import Banner from "../sections/Banner";
import ContactSection from "../sections/ContactSection";
import saudi from "../../resources/images/saudi.jpg";
import aboutImage from "../../resources/images/about.png";
import ParagraphSection from "../sections/ParagraphSection";
import ClientSlider from "../sections/ClientSlider";

export default function About() {
  return (
    <Layout>
      <Banner
        title="About Us"
        text="Edraak is a Saudi company since 1997 specialized in commercial and industrial supplies such as tools, machines and spare parts for general contracting projects, technology, trade, distribution of consumer goods & products and logistics services. Our services meet the growing needs of consumers and help government sectors and leading companies in the Kingdom to achieve their goals and meet their needs and aspirations with the best efficiency and quality."
        bg={saudi}
      />

      <div className="bg-white">
        <div className="container mx-auto p-10">
          <div className="md:h-96 h-full w-full">
            <img className="w-full h-full object-cover rounded-lg shadow object-top" src={aboutImage} />
          </div>

          <div className="pt-6">
            <ParagraphSection
              title="OUR VISION"
              text="To be a pioneer in meeting the needs and aspirations of our customers 
              and help them in achieving their business goals effectively"
            />
          </div>

          <div>
            <div className="w-full py-6 space-y-3">
              <div className="e-title">OUR VALUES</div>
              <div className="text-gray-600 text-lg">
                <ul class="list-disc list-inside">
                  <li>Integrity and Honesty</li>
                  <li>Quality and Excellence</li>
                  <li>Customer Satisfaction</li>
                  <li>Strengthening Internal Capabilities</li>
                  <li>Fulfilling Commitments</li>
                </ul>
              </div>
            </div>
          </div>

          <div>
            <ParagraphSection
              title="OUR MISSION"
              text="We are committed to be the first choice for our customers by 
              providing the best quality products & services while helping them 
              define and apply the best options throughout the effective 
              partnership, outstanding customer service, and top talent team"
            />
          </div>

          <div>
            <ParagraphSection
              title="BUSINESS STRATEGY"
              text="We commit to offer our clients best quality with world class 
              services for every project. We perform continuous development to 
              our capabilities & resources through sustainable updates of 
              technology and skills in order to deliver high quality products and 
              optimal services."
            />
          </div>

          <div>
            <div className="w-full py-6 space-y-3">
              <div className="e-title">OUR FUTURE</div>
              <div className="text-gray-600 text-lg">
                We are determined to continue investing with high focus on production capability , trading and service
                sectors along with keeping an eye to recruit best talent and highest skills in the market.
                <br />
                Our Growth strategy has 3 pillars
                <div className="grid md:grid-cols-3 grid-cols-1 gap-6 py-4">
                  <div className="p-4 bg-gray-100 rounded-lg">
                    <div className="uppercase font-bold text-primary-500 text-lg">Breakthrough Innovations</div>
                    <div className="text-gray-600">
                      Through conducting market studies , consumer researches to reach innovative products with world
                      class standards and high end services to meet Saudi market needs
                    </div>
                  </div>
                  <div className="p-4 bg-gray-100 rounded-lg">
                    <div className="uppercase font-bold text-primary-500 text-lg">Geographical Expansion</div>
                    <div className="text-gray-600">
                      We are committed to reach customers with agility to provide best services to our partner across
                      Kingdom of Saudi Arabia
                    </div>
                  </div>
                  <div className="p-4 bg-gray-100 rounded-lg">
                    <div className="uppercase font-bold text-primary-500 text-lg">People Development</div>
                    <div className="text-gray-600">
                      We believe on the importance of everyone in our team , we continue develop , train and retain all
                      our employees
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Clients */}
      <ClientSlider />
      {/* Clients */}

      <ContactSection />
    </Layout>
  );
}
